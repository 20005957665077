interface UsercentricsEvent extends Event {
  detail: {
    event: "consent_status";
    "YouTube Video"?: boolean;
  };
}

const getIsUsercentricsEvent = (event: Event): event is UsercentricsEvent =>
  (event as UsercentricsEvent).detail.event === "consent_status";

const addUsercentricsConsentEventListener = (
  callback: (event: UsercentricsEvent) => void,
) =>
  window.addEventListener(
    "UsercentricsCustomEvent",
    (event) => getIsUsercentricsEvent(event) && callback(event),
  );

export const initVideo = () => {
  let isYouTubeVideoConsented = false;

  addUsercentricsConsentEventListener((event) => {
    if (
      !event.detail["YouTube Video"] ||
      isYouTubeVideoConsented ||
      document.documentElement.clientWidth < 992
    )
      return;

    const allYoutubeVideosContainer = document.querySelectorAll(
      ".js-video-container",
    );

    [...allYoutubeVideosContainer].map((el) => {
      const iframe = el.querySelector<HTMLIFrameElement>(".video-iframe");
      const src = iframe?.dataset["src"];

      if (src) iframe.src = src;

      el.classList.add("active");
    });

    isYouTubeVideoConsented = true;
  });
};
