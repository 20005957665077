export const initCrosswordInput = () => {
  const inputs = [
    ...document.querySelectorAll<HTMLInputElement>(".crossword-board__item"),
  ];

  document.addEventListener("keydown", (e) => {
    if (["Backspace", "Tab", "Shift"].includes(e.key)) return;

    const target = e.target as HTMLElement | null;
    const input = target?.closest<HTMLInputElement>(".crossword-board__item");

    if (!input) return;

    e.preventDefault();

    const isLetter = /[A-Z]/i.test(e.key) && e.code.includes("Key");
    if (!isLetter) return;

    input.value = e.key;

    const currInputIndex = inputs.indexOf(input);
    const nextinputIndex = (currInputIndex + 1) % inputs.length;
    const nextInput = inputs[nextinputIndex];
    nextInput?.focus();
  });
};
