interface WindowWithUsercentrics extends Window {
  UC_UI?: {
    showFirstLayer: () => void;
    showSecondLayer: () => void;
    isInitialized: () => boolean;
  };
}

export const handleUsercentricsButtons = () => {
  const allButtons = document.querySelectorAll(
    '[href="#show-usercentrics-modal"]',
  );

  Array.from(allButtons).forEach((element) => {
    element.addEventListener("click", (event) => {
      event.preventDefault();
      ((window as unknown) as WindowWithUsercentrics).UC_UI?.showSecondLayer();
    });
  });
};
