import i18n, { currentLanguage } from "../i18n";
import { initLightbox } from "./lightbox";

interface Image {
  img: string;
  imgFull: string;
  copyright: string | undefined;
  description: string | undefined;
}

interface Images {
  total: number;
  images: Image[];
}

let page = 0;
const limit = 12;

const fetchData = async <Response>(
  url: string,
): Promise<Response | undefined> => {
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status !== 200) return;
  return await response.json();
};

const getLightBoxContent = (item: Image) => {
  if (!item.copyright && !item.description) return "";

  const title = item.copyright && `title: Foto: ${item.copyright};`;
  const desc = item.description && `description: ${item.description}`;

  return `data-glightbox="${title ?? ""} ${desc ?? ""}"`;
};

const handleImages = async (moreButton?: HTMLElement) => {
  const galleryEl = document.querySelector(".js-gallery");
  const data = await fetchData<Images>(
    `/${currentLanguage}/images-data?start=${page * limit}&limit=${limit}`,
  );

  if (!data?.total) {
    galleryEl?.insertAdjacentHTML(
      "beforeend",
      `<div class="gallery-empty">${i18n.noImages}</div>`,
    );
    moreButton?.remove();
    return;
  }

  data.total < limit && moreButton?.remove();

  const html = data.images
    .map(
      (item) => `
        <a href="${
          item.imgFull
        }" class="gallery-link lightbox" ${getLightBoxContent(item)}>
          <img loading="lazy" class="gallery-img" width="825" height="550" src="${
            item.img
          }" srcset="${item.img} 320w, ${item.img} 620w, ${item.img} 920w, ${
            item.img
          } 1220w" sizes="(max-width:768px) 100vw, (max-width:992px) 50vw, 25vw" />
        </a>
      `,
    )
    .join("");

  galleryEl?.insertAdjacentHTML("beforeend", html);

  initLightbox();

  return data;
};

export const initGallery = async () => {
  const moreButton = document.querySelector<HTMLElement>(".js-more-button");

  moreButton?.addEventListener("click", async () => {
    page = page + 1;

    const data = await handleImages();

    if (!data || page * limit + limit < data.total) return;
    moreButton.remove();
  });
};
