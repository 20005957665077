export const handleScrollHeader = () => {
  let oldScrollY = window.scrollY;
  const nav = document.querySelector(".nav");
  const headerElement = document.querySelector<HTMLElement>(".js-header");

  const handleHeader = () => {
    if (nav?.classList.contains("active")) return;

    headerElement?.classList.toggle(
      "header-shown",
      oldScrollY > window.scrollY,
    );

    if (headerElement?.dataset["home"]) {
      headerElement.classList.toggle("header-transparent", window.scrollY <= 0);
    }

    oldScrollY = window.scrollY;
  };

  window.addEventListener("scroll", handleHeader);
};
